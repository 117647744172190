.quoteContainer {
    background: #1E1E1E;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 10%;

    @media screen and (max-width: 620px) {
        height: 600px;
    }
}

.quoteText {
    display: flex;
    color: #FFF;
    font-size: 28px;
    font-family: Helvetica Neue, serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 2%;
}

.quoteAuthor {
    display: flex;
    color: #FFF;
    font-size: 22px;
    font-family: Helvetica Neue, serif;
    font-style: normal;
    line-height: normal;
}

.coverImage {
    height: 500px;
    width: 100%;
    object-fit: cover;
    filter: grayscale(100%);

    @media screen and (max-width: 620px) {
        height: 400px;
    }
}

.whoWeAreContainer {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    justify-content: center;
    align-items: center;
    padding: 10% 5%;

    @media screen and (max-width: 468px) {
        padding: 15% 8%;
    }
}

.titleText {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 50px;
    gap: 20px;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
    }
}

.whoWeAreTitle {
    display: flex;
    color: #370894;
    font-size: 64px;
    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: 700;

    @media screen and (max-width: 468px) {
        font-size: 48px;
        margin-bottom: 10px;
    }
}

.whoWeAreText {
    display: flex;
    color: #1E1E1E;
    font-size: 20px;
    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 60%;
    line-height: 25px;

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 16px;
    }
}

.whoWeAreImagesContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }
}

.whoWeAreImage {
    display: flex;
    height: 300px;
    object-fit: cover;

    @media screen and (max-width: 1350px) {
        height: 250px;
    }

    @media screen and (max-width: 1100px) {
        height: 200px;
    }

    @media screen and (max-width: 900px) {
        height: 400px;
    }

    @media screen and (max-width: 600px) {
        height: 300px;
    }

    @media screen and (max-width: 450px) {
        height: 200px;
    }
}


.introTitle {
    display: flex;
    flex-direction: column;

    color: #370894;
    font-family: Helvetica Neue, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
}


.partnerImage {
    display: flex;
    width: 225px;
    height: 225px;
    border-radius: 250px;
    margin: 15px;
    border: black 1px solid;
    object-fit: cover;
}

.telusImage {
    transform: scale(0.4); /* Adjust the scale as needed to 'zoom out' */
}



