.teamCardsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}


.teamInnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 50px;
}

.teamComingSoonContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10vw;
}