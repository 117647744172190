.teamCardsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}


.teamInnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 50px;
}

