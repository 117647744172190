.eventCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 50px 50px 50px;
}

.cardContent {
    color: #000;
    font-family: Helvetica Neue, sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 25px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 150px;

    @media screen and (max-width: 600px) {
        margin-top: 100px
    }
}
