.landingBackground {
    background: linear-gradient(180deg, #370894 0%, #1E1E1E 100%);
    height: 900px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 620px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.leftImage {
    width: 450px;
    height: 450px;
    border-radius: 450px;
    margin: 0 20px 0 40px; /* Adjusted margin to center the image */
    object-fit: cover;

    @media screen and (max-width: 1024px) {
        width: 300px;
        height: 300px;
    }

    @media screen and (max-width: 620px) {
        margin: 0 20px 40px 20px;
    }
}

.rightTitles {
    display: flex;
    flex-direction: column;
    margin: 0 20px 0 20px; /* Adjusted margin to center the titles */
    
    @media screen and (max-width: 620px) {
        margin: 0 20px 0 20px;
        text-align: center;
        align-items: center;
    }
}

.landingTitle {
    color: #FFF;
    font-size: 56px;
    font-family: Helvetica Neue, serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    max-width: 700px;

    @media screen and (max-width: 1024px) {
        font-size: 46px;
    }
}

.landingSubtitle {
    color: #FFF;
    font-size: 26px;
    font-family: Helvetica Neue, serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 800px;

    @media screen and (max-width: 1300px) {
        font-size: 26px;
        width: 500px;
    }

    @media screen and (max-width: 768px) {
        font-size: 20px;
        width: 300px;
    }
}