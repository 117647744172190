.eventsContainer {
    margin-bottom: 50px;
}

.eventsContent {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .eventsContent {
        flex-direction: column; /* Change to column layout for mobile devices */
        align-items: stretch; /* Stretch items vertically */
    }
}

.eventsHeader {
    display: flex;
    justify-content: center;
    color: #000;
    text-align: center;
    font-family: Helvetica Neue, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 50px;
}

.highlightsContainer {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    gap: 20px;
}

.highlightImage {
    width: 100%;
    max-width: 400px; /* Adjust the max-width as needed */
    height: auto;
    border-radius: 10px;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .highlightImage {
        max-width: 200px; /* Adjust the max-width as needed */
    }
}
