.buttonsContainer {
    display: flex;
    justify-content: center;
}

.buttonGroup {
    display: flex;
    justify-content: center;
    margin: 75px;
    box-shadow: none;
}

#ourTeam {
    display: block;
}

#ourProgram {
    display: none;
}